import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import useLocation from "react-use/esm/useLocation";
import DefaultLayout from "../layouts/default";
import KontaktGrid from "../components/Layouting/KontaktGrid";
import Kontakt from "../components/Kontakt";

interface KontaktPageProps {
  cms: {
    entries: [
      {
        title: string;
        bild: [
          {
            url: string;
          }
        ];
        beschreibung: string;
        bistroNummer: string;
        latitude: number;
        longitude: number;
        mapsLink: string;
        mapMarker: [{ url: string }];
      }
    ];
  };
}

const KontaktPage: React.FC<KontaktPageProps> = () => {
  const data = useStaticQuery(graphql`
    query {
      cms {
        entries(type: "kontakt") {
          title
          ... on CMS_main_kontakt_Entry {
            bild {
              url
            }
            beschreibung
            bistroNummer
            latitude
            longitude
            email
            telefon
            adresse
            mapsLink
          }
        }
      }
    }
  `);

  const { hash } = useLocation();

  return (
    <DefaultLayout
      beschreibung={
        (data.cms.entries.length && data.cms.entries[0].beschreibung) || ""
      }
      titel="Kontakt"
    >
      <KontaktGrid>
        {data.cms.entries.map((entry, index) => (
          <Kontakt entry={entry} hash={hash} key={index} />
        ))}
      </KontaktGrid>
    </DefaultLayout>
  );
};

export default KontaktPage;

// import React from "react";

// const Kontakt = () => <p>Kontakt</p>;

// export default Kontakt;
