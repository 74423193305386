import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../../styles/variables";

const MarkerDiv = styled.div`
  position: absolute;
  left: 0;
  div {
    font: 60px/60px "RC Bold";
    color: ${colors.orange};
    margin-left: -50%;
    margin-top: -50%;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    div  {
      font: 77px/77px "RC Bold";
    }
  }
`;

const MapMarker: React.FC = ({ children }) => (
  <MarkerDiv>
    <div>{children}</div>
  </MarkerDiv>
);

export default MapMarker;
