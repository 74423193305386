import React, { useState } from "react";
import styled from "styled-components";
import ReactMapGL, { Marker } from "react-map-gl";
import { breakpoints } from "../../styles/variables";
import MapMarker from "./MapMarker";

const MapWrapper = styled.div`
  width: 100vw;
  height: 100vw;
  max-width: 497.5px;
  max-height: 497.5px;
  margin: 0 auto 30px;
  > * {
    border-radius: 1000px;
    overflow: hidden;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    width: calc(1025px - 30px);
    height: calc(1025px - 30px);
    margin: 0 auto 30px;
  }
`;

interface MapProps {
  latitude: number;
  longitude: number;
  mapMarker: string;
}

const Map: React.FC<MapProps> = ({ latitude, longitude, nummer }) => {
  const [viewport, setViewport] = useState({
    width: "100%",
    height: "100%",
    latitude: latitude,
    longitude: longitude,
    zoom: 15,
  });

  return (
    <MapWrapper>
      <ReactMapGL
        {...viewport}
        onViewportChange={setViewport}
        mapboxApiAccessToken="pk.eyJ1IjoiZ29lYXN0IiwiYSI6ImNqcWY5OXJmajIxMG00MnQ3ZXpsMGFwcDIifQ.5hMtuM7NCx7STL38ftqtMg"
        style={{ maxWidth: "497.5px", maxHeight: "497.5px" }}
        mapStyle="mapbox://styles/goeast/ckljqh2ns0nd317uke1bgmi68"
      >
        <Marker longitude={longitude} latitude={latitude}>
          <MapMarker>{nummer}</MapMarker>
        </Marker>
      </ReactMapGL>
    </MapWrapper>
  );
};

export default Map;
