import React from "react";
import styled from "styled-components";
import { breakpoints } from "../../styles/variables";

const GridDiv = styled.div`
  margin-bottom: 55px;

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    grid-gap: 30px;
    width: 100%;
    max-width: 1025px;
    margin-bottom: 120px;
  }
`;

const KontaktGrid: React.FC = ({ children }) => <GridDiv>{children}</GridDiv>;

export default KontaktGrid;
