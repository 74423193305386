import React from "react";
import styled from "styled-components";
import { breakpoints, imgix } from "../../styles/variables";
import { Background } from "react-imgix";

const ImageDiv = styled(Background)`
  width: 100vw;
  height: 71.733333vw;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  margin-bottom: 20px;

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    width: calc(995px / 2);
    height: 552px;
  }
`;

interface KontaktImageProps {
  image: string;
}

const KontaktImage: React.FC<KontaktImageProps> = ({ image }) => (
  <>{image ? <ImageDiv src={image} imgixParams={imgix.smaller} /> : ""}</>
);

export default KontaktImage;
