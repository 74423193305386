import React from "react";
import styled from "styled-components";

const AddressElem = styled.address`
  font: 14px/23px "RC Regular";
  letter-spacing: 0.7px;
  text-align: center;

  strong {
    font-family: "RC Bold";
  }

  a {
    text-decoration: none;
    color: #000;
  }
`;

interface AdresseProps {
  adresse: string;
  email: string;
  telefon: string;
  nummer: string;
  maps: string;
}

const Adresse: React.FC<AdresseProps> = ({
  adresse,
  email,
  telefon,
  nummer,
  maps,
}) => (
  <AddressElem>
    <strong>BistroZ {nummer}</strong>
    <br />
    {adresse} <br />
    <a href={`tel:${telefon}`}>{telefon}</a>,{" "}
    <a href={`mailto:${email}`}>{email}</a>
    <br />
    {maps && maps != "" ? (
      <>
        <br />
        <a href={maps} target="_blank" rel="noreferrer">
          Google Maps
        </a>
      </>
    ) : (
      ""
    )}
  </AddressElem>
);

export default Adresse;
