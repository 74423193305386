import React from "react";
import styled from "styled-components";
import { breakpoints, colors } from "../styles/variables";
import KontaktImage from "./Kontakt/KontaktImage";
import TextWrapper from "./Layouting/TextWrapper";
import Map from "./Kontakt/Map";
import Text from "./Text";
import Adresse from "./Kontakt/Adresse";

const KontaktElem = styled.section`
  display: none;
  h2 {
    font: 28px / 50px "RC Regular";
    letter-spacing: 2.8px;
    text-align: center;
    margin-bottom: 17px;

    strong,
    b {
      font-family: "RC Bold";
      color: ${colors.orange};
    }
  }

  &.activeMobile {
    display: block;
  }

  @media screen AND (min-width: ${breakpoints.desktop}px) {
    max-width: 497.5px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &.activeMobile {
      display: flex;
    }
  }
`;

interface KontaktProps {
  entry: {
    bistroNummer: string;
    bild: [
      {
        url: string;
      }
    ];
    latitude: number;
    longitude: number;
    adresse: string;
    email: string;
    telefon: string;
  };
  hash: string;
}

const Kontakt: React.FC<KontaktProps> = ({ entry, hash }) => (
  <KontaktElem
    className={hash == "#" + entry.bistroNummer ? "activeMobile" : ""}
  >
    <div>
      <KontaktImage image={entry.bild[0].url} />
      <h2>
        Bistro-<strong>Z</strong>
      </h2>
    </div>
    <div>
      <Map
        latitude={entry.latitude}
        longitude={entry.longitude}
        nummer={entry.bistroNummer}
      />
      <Adresse
        adresse={entry.adresse}
        email={entry.email}
        telefon={entry.telefon}
        nummer={entry.bistroNummer}
        maps={entry.mapsLink}
      />
    </div>
  </KontaktElem>
);

export default Kontakt;
